import { Headline } from '@/components/Headline';
import { Title } from '@/components/Typography';
import { AVAILABLE_THEMES } from '@/styles/constants';
import NextImage from '@/components/NextImage';

export const TitleElement = ({
  text,
  id,
  themeName,
  tag,
}: {
  text: string;
  id: string;
  themeName?: string;
  tag?: string;
}) => {
  if (themeName === AVAILABLE_THEMES.suprema) {
    return (
      <>
        <NextImage
          src="/assets/hygraph/output=format:webp/resize=fit:max,width:1000/quality=value:75/compress/5zlLzoUSSOt6mCTtQruQ"
          alt="Hero image"
          priority
          className="mx-auto mb-3"
          width={282}
          height={99}
        />
        <Headline
          data-test={[`${id}-title`]}
          as="h1"
          type="xl"
          className="font-primary mb-3  hidden"
        >
          {text}
        </Headline>
      </>
    );
  }

  return (
    <Title data-test={[`${id}-title`]} tag={tag || 'div'} type="md">
      {text}
    </Title>
  );
};
